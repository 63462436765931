import React, { Component } from "react";
import { Link } from 'react-router-dom';
const selfServicePortfolioImg = <img src="/assets/images/portfolio/portfolioOne.png" alt="Self Service" />;
const visaPortfolioImg = <img src="/assets/images/portfolio/portfolioVisa.png" alt="Blockchain" />;
const neoBankPortfolioImg = <img src="/assets/images/portfolio/portfolioNeobank.png" alt="Cards" />;
const springPortfolioImg = <img src="/assets/images/portfolio/portfolioSpring.png" alt="Spring" />;
const kryptoPortfolioImg = <img src="/assets/images/portfolio/portfolioKrypto.png" alt="Cards" />;

const PortfolioList = [
    {
        image: visaPortfolioImg,
        category: 'Cards',
        title: 'Visa Prepaid',
        description: 'USD and NGN Cards',
        link: 'https://app.wallet.ng'
    },
    {
        image: neoBankPortfolioImg,
        category: 'Enterprise',
        title: 'Wallets Company',
        description: 'Payments operating system',
        link: 'https://wallets.company'
    },
    {
        image: kryptoPortfolioImg,
        category: 'Blockchain',
        title: 'Krypto',
        description: "Simple ethereum wallet for Wallets customers",
        link: 'https://krypto.africa'
    },
    {
        image: selfServicePortfolioImg,
        category: 'Utilities',
        title: 'Self Service',
        description: 'Monitor Tansactions and Account Management',
        link: 'https://apps.apple.com/lt/app/self-service-by-wallets/id6447111028'
    }
     
]


class PortfolioMasonry extends Component{
    render(){
        const {column } = this.props;
        const list = PortfolioList.slice(0 , this.props.item);
        return(
            <React.Fragment>
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className="im_portfolio">
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                    <a target="_blank" href={value.link}>
                                        {value.image}
                                    </a>    
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="portfolio_heading">
                                        <div className="category_list">
                                            <a target="_blank" href={value.link}>{value.category}</a>
                                        </div>
                                        <h4 className="title"><a target="_blank" href={value.link}>{value.title}</a></h4>
                                    </div>
                                    <div className="portfolio_hover">
                                        <p>{value.description}</p>
                                    </div>
                                </div>
                            </div>
                            <a className="transparent_link" href={value.link} target="_blank"></a>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioMasonry;